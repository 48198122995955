import React, { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../../../contexts/layoutContext';
import { Toast } from '../toast';
import { Container } from './Popup.styles';

export const Popup = () => {
  const { isOpen, popup, dispatch } = useContext(LayoutContext);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (popup) {
      setActive(true);
      setTimeout(() => {
        setActive(false);
        setTimeout(() => {
          dispatch({ type: 'SET_POPUP', payload: undefined });
        }, 2000);
      }, 5000);
    }
  }, [popup, dispatch]);

  if (!popup?.message) {
    return null;
  }
  return (
    <Container type={popup?.type} active={active} menuIsOpen={isOpen && active}>
      <Toast
        type={popup?.type || 'success'}
        heading={popup?.heading}
        message={popup?.message || 'Message'}
      />
    </Container>
  );
};
