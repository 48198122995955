import { useContext, useState } from 'react';
import { IEditAccount, LayoutContext } from '../../contexts/layoutContext';
import {
  Maybe,
  useClearPaymentMethodMutation,
  useSavePaymentMethodMutation,
  useSubscriptionDataQuery,
  useUpdateBillingAddressMutation,
} from '../../generated/graphql';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { IAddressFormProps } from '../../components/shared/formElements/address/Address';
import { stripeCountryCodes } from '../constants';

export const useBilling = () => {
  const { account, selectedVenueObject, dispatch } = useContext(LayoutContext);
  const { loading, error, data } = useSubscriptionDataQuery({
    fetchPolicy: 'cache-and-network',
    skip: !account,
  });
  const userData = data?.me?.user;
  const stripe = useStripe();
  const elements = useElements();
  const [savePaymentMethodMutation] = useSavePaymentMethodMutation();
  const [updateBillingAddressMutation] = useUpdateBillingAddressMutation();

  const [
    clearPaymentMethodMutation,
    clearPaymentMethod,
  ] = useClearPaymentMethodMutation();
  const [isSaving, setSaving] = useState(false);
  const [isRemovingCard, setRemovingCard] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Maybe<string> | undefined>();

  const savePaymentMethod = async (
    formData: IAddressFormProps,
    totalActiveSeconds: number
  ) => {
    const cardElement = elements?.getElement(CardElement);
    if (!stripe || !cardElement) {
      return;
    }
    setSaving(true);
    setErrorMessage('');

    const countryCode = stripeCountryCodes.find(
      (c) => c.country === formData.country
    );

    const address = {
      line1: formData.addressLine1 || '',
      line2: formData.addressLine2 || '',
      city: formData.city || '',
      state: formData.state || '',
      country: countryCode?.code || formData.countryShort || '',
      postal_code: formData.postcode || '',
    };

    // Create stripe payment method
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: `${userData?.firstName} ${userData?.lastName}`,
        email: userData?.email,
        address,
      },
    });

    // check for errors
    if (error || !paymentMethod) {
      console.log('[error]', error);
      setErrorMessage(error?.message ?? '');
      setSaving(false);
      return;
    }

    // Save payment method to user
    try {
      const response = await savePaymentMethodMutation({
        variables: {
          input: {
            paymentMethodId: paymentMethod.id,
            address: formData,
            venueId: selectedVenueObject?.id!,
            totalActiveSeconds,
          },
        },
        refetchQueries: ['useSubscriptionDataQuery'],
        awaitRefetchQueries: true,
      });

      // Upon success, redirect user back to somewhere
      if (response.data?.savePaymentMethod.successful) {
        dispatch({
          type: 'SET_POPUP',
          payload: {
            type: 'success',
            heading: 'Billing Updated',
            message: `Your Billing Details were updated Successfully`,
          },
        });
      } else {
        console.error(response.data);
        setErrorMessage(
          response.data?.savePaymentMethod.error ?? 'Failed to save card'
        );
        console.log('Card failed to save');
      }
      setSaving(false);
      return {
        success: true,
        response,
      };
    } catch (error) {
      setErrorMessage('Failed to save card');
      console.log('error', error);
      setSaving(false);
      return {
        success: false,
      };
    }
  };

  const saveBillingAddress = async (
    paymentMethodId: Maybe<string> | undefined,
    formData: IAddressFormProps,
    totalActiveSeconds: number
  ) => {
    setSaving(true);
    setErrorMessage('');

    if (!paymentMethodId) {
      setErrorMessage('Failed to update billing address');
    }

    // Save payment method to user
    try {
      if (paymentMethodId) {
        const response = await updateBillingAddressMutation({
          variables: {
            input: {
              paymentMethodId,
              address: formData,
              venueId: selectedVenueObject?.id!,
              totalActiveSeconds,
            },
          },
        });

        // Upon success, redirect user back to somewhere
        if (response.data?.updateBillingAddress.successful) {
          // Call back to the component that is embedding the card field
          console.log('Billing address saved successfully', response);

          dispatch({
            type: 'SET_POPUP',
            payload: {
              type: 'success',
              heading: 'Billing Address Updated',
              message: `Your Billing Address was updated Successfully`,
            },
          });
        } else {
          console.error(response.data);
          setErrorMessage(
            response.data?.updateBillingAddress.error ??
              'Failed to update billing address'
          );
          console.log('Failed to update billing address');
        }
        setSaving(false);
        return {
          success: true,
          response,
        };
      } else {
        setErrorMessage('Failed to update billing address');
        setSaving(false);
        return {
          success: false,
        };
      }
    } catch (error) {
      setErrorMessage('Failed to update billing address');
      console.log('error', error);
      setSaving(false);
      return {
        success: false,
      };
    }
  };

  const handleCancelOverlayCTA = () => {
    dispatch({
      type: 'SET_EDIT_ACCOUNT',
      payload: {} as IEditAccount,
    });
  };

  const confirmClearCard = async () => {
    try {
      setRemovingCard(true);
      console.log('Clearing payment method...');
      const response = await clearPaymentMethodMutation({
        refetchQueries: ['SubscriptionData'],
      });
      console.log('Cleared payment method.');
      setRemovingCard(false);
      if (response.data?.clearPaymentMethod.successful) {
        dispatch({
          type: 'SET_POPUP',
          payload: {
            type: 'success',
            heading: 'Billing Updated',
            message: `Your card details were removed Successfully`,
          },
        });
      } else {
        setErrorMessage(response.data?.clearPaymentMethod.error);
        dispatch({
          type: 'SET_POPUP',
          payload: {
            type: 'error',
            heading: 'Billing Error',
            message: `There was an error when removing your card details`,
          },
        });
      }
      dispatch({
        type: 'SET_EDIT_ACCOUNT',
        payload: {} as IEditAccount,
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const removeCard = () => {
    dispatch({
      type: 'SET_EDIT_ACCOUNT',
      payload: {
        showingRemovingCardModal: true,
      } as IEditAccount,
    });
    dispatch({
      type: 'SET_OVERLAY',
      payload: {
        heading: 'Confirm Removal of Card Payment',
        subHeading:
          'Upon removing your Card Payment Details your account will automatically change to our free account until new billing details are added. At which time you will need to select which account you would like to upgrade to.',
        buttonText: 'Confirm Card Removal',
        linkText: 'cancel',
        toggleSliderOnButtonCTA: false,
        toggleSliderOnLinkCTA: false,
        buttonCTA: confirmClearCard,
        linkCTA: handleCancelOverlayCTA,
      },
    });
  };

  return {
    loading,
    data,
    error,
    userData,
    isRemovingCard,
    isSaving,
    errorMessage,
    clearPaymentMethod,
    saveBillingAddress,
    savePaymentMethod,
    setSaving,
    removeCard,
    setErrorMessage,
  };
};
